const axios = require("axios");
axios.defaults.withCredentials = true;


const compareFunction = function (a, b) {
    var x =parseInt(a.sn)
    var y =parseInt(b.sn)
    return x-y
}



export default {


    computed:{
        motors_sorted(){
            var arr=[]
            if (this.sortparams.q){
                for(var i in this.motors){
                    if (this.motors[i].sn.indexOf(this.sortparams.q)>=0){
                        arr.push(this.motors[i])
                    }
                }

            }else{
                arr = this.motors
            }

            if (this.sortparams.order){
                arr=arr.sort(compareFunction)
            }

            return arr
        },
        pocetVystrah(){
            var problems=0
            for(var i in this.motors){
                var m = this.motors[i]
                //  console.log('pruba', m.intervalS + m.last_service, m.last_measurement,m.intervalL + m.last_lubrication )
                //  console.log('pruba2', m.intervalS , m.intervalM , m.intervalL )

                if (m.intervalS>0  && m.intervalS + m.last_service < 0 ) { problems++; }
                if (m.intervalM>0 && m.intervalM + m.last_measurement < 0 ) { problems++; }
                if (m.intervalL>0 && m.intervalL + m.last_lubrication < 0 ) { problems++; }

                if (!m.last_lubrication && m.last_lubrication !==0 && m.commissioning) problems++;
                if (!m.last_measurement && m.commissioning) problems++;

                //console.log('lub com ',m.last_lubrication , m.commissioning, "=>",(!m.last_lubrication && m.commissioning) )
                //console.log("m.last_measurement ", (!m.last_measurement && m.commissioning))
            }
            //console.log('proble', problems)
            return problems;
        }

    },

    methods: {

        loadMotors(){
            let params={t: Date.now()}
            if (this.$route.query.user_id && this.$route.query.user_id>0){
                params.user_id = this.$route.query.user_id
            }
            //console.log("loading")
            const that = this
            axios.get(process.env.VUE_APP_API_ROOT_URL + "/motors", {params: params}).then(result => {

                if (result.status == 200) {
                    this.motors = result.data

                    setTimeout(function(){
                        that.loadMotors();
                    }, 1000 * 30)
                }
            })
        },

        sortovat(param){
            console.log("child serazeni", param)
            this.sortparams=param
        }


    }






}
