<template>
<div id="dashboard">
  <div class="row">
    <div class="col-md-6 crm-nahledy" style="">
      <div class="col-4book pt-4 mr-0" id="col4book" style="">
        <Mikroblog ref="mikroblog1"
                   :enable_editor="true"
                   :itemtype="0"></Mikroblog>
      </div>
    </div>
    <div class="col-md-6 pt-4" id="motor-list">

      <div class="monitiring-header ">Monitoring</div>

      <div class="list">
            <MotorItem v-for="motor in motors_sorted" :key="motor.token" :motor="motor"></MotorItem>
      </div>

    </div>

  </div>
</div>
</template>

<script>
import Mikroblog from "./mikroblog/mikroblog";
import motorsMixin from "@/mixins/motorsMixin";
import MotorItem from "../components/MotorItem";
export default {
  mixins: [motorsMixin],
  name: "Dashboard"  ,
  data() {
    return {
      motors:[],
      sortparams:{}

    };
  },
  mounted() {

    if (this.$store.getters.me.company && this.$store.getters.me.company.status=='rejected'   && this.$route.name!='nonconfirmed'){
      this.$router.push('/main/non-confirmed')
      return
    }

    this.loadMotors()
  },

  components: {MotorItem,    Mikroblog  }
}
</script>

<style scoped>

.monitiring-header{


  background: #eeeeee;
  padding: 6px;
  max-width: 385px;color: #444444;font-size: 0.9em;border-radius: 7px 7px 0 0;
  border: 1px solid #e0e0e0;

  margin-bottom: 15px;

}

</style>
